import {HttpContextToken} from "@angular/common/http";

export class Constants {
  public static ACCESS_TOKEN = () => localStorage.getItem('accessToken');
  public static TOKEN_REQUIRED = new HttpContextToken(() => true);
  public static paginationSizes = [5, 10, 20];
  public static paginationSizeDefault = 10;
}

export enum Permissions {
  UserManagement = 'permission-user-management',
  ExpertManagement = 'permission-expert-management',
  VoucherManagement = 'permission-voucher-management',
  Exchange = 'permission-exchange',
  BankAccounts = 'permission-bank-accounts',
  Banks = 'permission-banks',
  Kyc = 'permission-kyc',
}
